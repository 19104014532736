<template>
  <section>
    <div
      class="flex flex-col px-4 py-0 md:h-16 md:flex-row"
      :class="getVariantClass"
    >
      <!-- one -->
      <div class="w-full py-3 mt-3 md:mt-0 md:w-1/3">
        <div
          class="block mb-3 font-bold md:hidden text-14px text-oGray md:mb-0"
        >
          {{ one }}
        </div>
        <slot name="one"></slot>
      </div>
      <!-- two -->
      <div class="w-full py-3 mt-3 md:mt-0 md:w-1/3">
        <div
          class="block mb-3 font-bold md:hidden text-14px text-oGray md:mb-0"
        >
          {{ two }}
        </div>
        <slot name="two"></slot>
      </div>
      <!-- three -->
      <div class="w-full py-3 mt-3 md:mt-0 md:w-1/3">
        <div
          class="block mb-3 font-bold md:hidden text-14px text-oGray md:mb-0"
        >
          {{ three }}
        </div>
        <slot name="three"></slot>
      </div>
    </div>
    <div class="block w-full h-px mb-10 bg-gray-300 md:hidden" />
  </section>
</template>

<script>
export default {
  name: 'SGrid',
  props: ['one', 'two', 'three', 'variant'],
  computed: {
    getVariantClass() {
      if (this.variant === 'gray') return 'md:bg-gray-100'
      return 'md:bg-white'
    },
  },
}
</script>

<style></style>
