<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <div class="max-w-6xl mx-auto mt-3 lg:mt-10 md:mt-5" v-if="isLoaded">
      <!-- one -->
      <div class="flex items-center justify-between">
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-clock text-22px text-oTeal"></i>
          <span class="pl-3">
            Business Hour
          </span>
        </div>
        <div></div>
      </div>
      <!-- two -->
      <div class="hidden py-6 border-b border-oLightestGray md:flex ">
        <div class="w-1/3 font-bold text-14px text-oGray">Working Day</div>
        <div class="w-1/3 ml-2 font-bold text-14px text-oGray">
          Starting Time
        </div>
        <div class="w-1/3 -ml-4 font-bold text-14px text-oGray">
          Ending Time
        </div>
      </div>
      <div class="block h-px mt-6 md:hidden"></div>

      <template v-for="(item, index) in schedules">
        <s-grid
          one="Working Day"
          two="Starting Time"
          three="Ending Time"
          :key="index"
          :variant="index % 2 ? 'gray' : 'white'"
        >
          <template v-slot:one>
            <div class="flex mt-3">
              <div>
                <t-toggle
                  variant="success"
                  v-model="item.is_active"
                  @change="onOff(item, $event)"
                />
              </div>
              <div class="ml-4 font-bold text-14px text-oGray">
                {{ item.day }}
              </div>
            </div>
          </template>
          <template v-slot:two>
            <div class="w-4/5">
              <ValidationObserver class="flex items-center w-full">
                <form
                  @submit.prevent
                  enctype="multipart/form-data"
                  class="w-full"
                >
                  <ValidationProvider vid="friday" rules="" v-slot="{ errors }">
                    <template
                      v-if="
                        getInputLockState(`${lowercase(item.day)}_start_time`)
                      "
                    >
                      <text-input v-model="item.start_time" :disabled="true" />
                    </template>
                    <template v-else>
                      <clock-picker
                        v-model="item.start_time"
                        placeholder="--:--"
                      />
                      <input-error-item :message="errors[0]" />
                    </template>
                  </ValidationProvider>
                </form>
                <div>
                  <anchor-button
                    @click="
                      toggleEditingState(
                        `${lowercase(item.day)}_start_time`,
                        null,
                        item
                      )
                    "
                    :text="getButtonText(`${lowercase(item.day)}_start_time`)"
                    :variant="
                      getButtonVariant(`${lowercase(item.day)}_start_time`)
                    "
                    class="ml-3"
                  />
                  <input-error-item />
                </div>
              </ValidationObserver>
            </div>
          </template>
          <template v-slot:three>
            <div class="w-4/5">
              <ValidationObserver class="flex items-center w-full">
                <form
                  @submit.prevent
                  enctype="multipart/form-data"
                  class="w-full"
                >
                  <ValidationProvider vid="friday" rules="" v-slot="{ errors }">
                    <template
                      v-if="
                        getInputLockState(`${lowercase(item.day)}_end_time`)
                      "
                    >
                      <text-input v-model="item.end_time" :disabled="true" />
                    </template>
                    <template v-else>
                      <clock-picker
                        v-model="item.end_time"
                        placeholder="--:--"
                      />
                      <input-error-item :message="errors[0]" />
                    </template>
                  </ValidationProvider>
                </form>
                <div>
                  <anchor-button
                    @click="
                      toggleEditingState(
                        `${lowercase(item.day)}_end_time`,
                        null,
                        item
                      )
                    "
                    :text="getButtonText(`${lowercase(item.day)}_end_time`)"
                    :variant="
                      getButtonVariant(`${lowercase(item.day)}_end_time`)
                    "
                    class="ml-3"
                  />
                  <input-error-item />
                </div>
              </ValidationObserver>
            </div>
          </template>
        </s-grid>
      </template>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import inPlaceEditingMixins from '@/mixins/inPlaceEditingMixin'
import { BusinessHourSettingsConfig } from '@/config/SettingsConfig'
import TextInput from '@/components/form/TextInput'
// import InputLabel from "@/components/form/InputLabel";
import AnchorButton from '@/components/form/AnchorButton'
import SGrid from '@/components/layout/SGrid'
import ClockPicker from '@/components/picker/clock/components/ClockPicker'

export default {
  name: 'ScheduleSettings',
  mixins: [inPlaceEditingMixins],
  components: {
    TextInput,
    // InputLabel,
    AnchorButton,
    // SubmitButton,
    SGrid,
    ClockPicker,
  },
  data() {
    return {
      isLoaded: false,
      schedules: [],
      inPlaceEditingState: {},
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
  },
  async created() {
    this.$http
      .get(BusinessHourSettingsConfig.api.index(this.orgId))
      .then((res) => {
        // console.log("settings", res.data);
        // this.schedules = res.data.data;
        const data = res.data.data
        data.forEach((item) => {
          this.mapDataWithModel(item)
        })
        // console.log(this.schedules);
        // console.log(this.inPlaceEditingState);
        this.isLoaded = true
      })
      .catch((err) => {
        console.log('settignsErr', err)
      })
  },
  methods: {
    formatTimeForClockPicker(time) {
      let parts = time.split(':')
      return parts[0] + ':' + parts[1]
    },
    lowercase(str) {
      return String(str).toLowerCase()
    },
    mapDataWithModel(item) {
      this.schedules.push({
        id: item.id,
        day: item.day,
        is_active: item.is_active,
        start_time: this.formatTimeForClockPicker(item.start_time),
        end_time: this.formatTimeForClockPicker(item.end_time),
      })

      const day = this.lowercase(item.day)

      // { friday_start_time: false }
      Vue.set(this.inPlaceEditingState, `${day}_start_time`, false)
      // Object.defineProperty(this.inPlaceEditingState, `${day}_start_time`, {
      //   value: false,
      //   writable: true
      // });

      // { friday_end_time: false }
      Vue.set(this.inPlaceEditingState, `${day}_end_time`, false)
      // Object.defineProperty(this.inPlaceEditingState, `${day}_end_time`, {
      //   value: false,
      //   writable: true
      // });
    },
    async inPlaceEditingRequest(group, key, data) {
      console.log(group, key)
      console.log(data)
      const formData = new FormData()
      formData.append('start_time', data.start_time)
      formData.append('end_time', data.end_time)

      let isStart = this.lowercase(data.day) + '_start_time'
      let isEnd = this.lowercase(data.day) + '_end_time'

      let toastTitle = ''
      let toastText = ''

      if (group === isStart) {
        toastTitle = `${data.day} Starting Time Updated`
        toastText = `${data.day} starting time is set to ${data.start_time}`
      }

      if (group === isEnd) {
        toastTitle = `${data.day} Ending Time Updated`
        toastText = `${data.day} ending time is set to ${data.end_time}`
      }

      await this.$http
        .patch(BusinessHourSettingsConfig.api.update(data.id), formData)
        .then((res) => {
          console.log(res)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          return true
        })
        .catch((err) => {
          // todo: reverse toggle state
          console.log('inPlaceE', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to update business hour',
            },
            3000
          )
        })
    },
    async onOff(item, val) {
      console.log(item, val)
      const formData = new FormData()
      formData.append('is_active', val)

      await this.$http
        .patch(BusinessHourSettingsConfig.api.update(item.id), formData)
        .then((res) => {
          console.log(res)
          let onOffText = val === true ? 'Opened' : 'Closed'
          let onOffTextAlt = val === true ? 'business day' : 'holiday'

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: `${item.day} is ${onOffText}`,
              text: `${item.day} is now a ${onOffTextAlt}`,
            },
            3000
          )
          return true
        })
        .catch((err) => {
          // todo: reverse toggle state
          console.log('onOffE', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to update business hour',
            },
            3000
          )
        })
    },
  },
}
</script>

<style lang="scss">
.grid-size {
  height: 83px;
}
</style>
